import '@danfoss/mosaic/css/mosaic.css';
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import settings from '../../config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import './styles.css';
import Modal from '../Modal';

export const UserMenuSelector: React.FC = () => {
    const profileEditUrl = settings.dipProfile.endpoint;
    const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
    const { loginWithRedirect, logout } = useAuth0();
    const { user, isAuthenticated } = useAuth0();

    const handleMenuExpand = useCallback(() => {
        setIsMenuActive((current) => !current);
    }, []);

    const logoutClick = () => {
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const loginClick = () => {
        loginWithRedirect();
    }

    const getInitials = (name: string): string => {
        return (
            name?.split(' ').map((namePart, _index) => {
                return namePart.charAt(0);
            }).join(' ') ?? ''
        );
    };

    return (
        <li slot="link" className={!isMenuActive ? 'df-nav-item' : 'df-nav-item open'}>
            {isAuthenticated ? (
                <>
                    <span className="df-icon df-context-menu-button" id="context-menu-button">
                        <button
                            className="df-btn-transparent df-text-regular df-link-tertiary"
                            onClick={handleMenuExpand}
                            aria-haspopup="true"
                            aria-controls="context-menu-dropdown"
                            aria-label="Account Options"
                            aria-expanded="false"
                            type="button"
                        >
                            <span className="df-icon df-addon-start df-color-tertiary">
                                <picture>
                                    <img src="/images/icons/header_User.svg" alt="Danfoss logo" />
                                </picture>
                            </span>
                            {getInitials(user?.name || '')}
                        </button>
                    </span>
                    {isMenuActive &&
                        <Modal>
                            <ul
                                className={`df-context-menu-overlay`}
                                id="context-menu-dropdown"
                                aria-labelledby="context-menu-button"
                                role="menu"
                                onMouseLeave={handleMenuExpand}
                            >
                                <>
                                    {profileEditUrl &&
                                        <li className='mb-4'>
                                            <Link className="df-link-secondary df-text-bold flex-align-baseline chevronBefore" to={profileEditUrl} target="_blank">
                                                
                                                <span>
                                                    Your profile
                                                    <span className='display-block'>
                                                        <div className='user-info'>{user?.name ?? ''}</div>
                                                        <div className='user-info'>{user?.email ?? ''}</div>
                                                    </span>
                                                </span>
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link className="df-link-secondary df-text-bold flex-align-baseline" onClick={() => logoutClick()} to={''}>
                                            <span className="df-icon df-addon-start">
                                                <svg role="img" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <title>Chevron link</title>
                                                    <use href="/images/icons/chevron-small.svg#chevron-small"></use>
                                                </svg>
                                            </span>
                                            <span>
                                                Log out
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            </ul>
                        </Modal>
                    }
                </>
            ) : (
                <Link className="sc-df-header df-link-tertiary inactive" onClick={() => loginClick()} to={''}>
                    <span className="df-icon df-addon-start df-color-secondary">
                        <picture>
                            <img src="/images/icons/header_User.svg" alt="Danfoss user image" />
                        </picture>
                    </span>
                    <span className="sc-df-header df-link-text">Log in</span>
                </Link>
            )}
        </li>
    );
};