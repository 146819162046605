import React from 'react';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = ({ }) => {

    return (

        <footer className="df-footer">
            <div className="df-footer-disclaimers">
                <nav className="df-container" aria-label="Disclaimers footer links">
                    <ul>
                        <li><a href="#" target="_blank" className="df-link-tertiary external">External link</a></li>
                        <li><a href="#" className="df-link-tertiary">Internal link</a></li>
                        <li><a href="#" target="_blank" className="df-link-tertiary external">External link</a></li>
                        <li><a href="#" className="df-link-tertiary">Internal link</a></li>
                        <li><a href="#" target="_blank" className="df-link-tertiary external">External link</a></li>
                        <li><a href="#" target="_blank" className="df-link-tertiary external">External link</a></li>
                    </ul>
                </nav>
            </div>
        </footer>)

}

export default Footer;