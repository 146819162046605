import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

export const AuthenticationProvider = ({
    children,
    domain,
    clientId,
}) => {

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: "https://MyDanfossAccountBackend.azurewebsites.net/",
                scope: 'openid profile email',
              }}            
        >
            {children}
        </Auth0Provider>
    );
};

export default AuthenticationProvider;