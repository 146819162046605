import React from 'react';
import './styles.css';

interface Props {
  children: any
}
const ModalPopup: React.FC<Props> = ({children}) => {


  return (
    <React.Fragment>
      <div className="overlay" />
        {children}
    </React.Fragment>
  );
}

export default ModalPopup;