import '@danfoss/mosaic/css/mosaic.css';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface ContentWrapperProps {
    setIsUserAuthenticated: Function;
    setLoading: Function;
    children: React.ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({setIsUserAuthenticated, setLoading, children}) => {

    const { getAccessTokenSilently, user } = useAuth0();

    useEffect(() => {
        const getUserToken = async () => {
            setLoading(true);
            
            try {
            
              const accessToken = await getAccessTokenSilently();
        
              if (accessToken) {
                setIsUserAuthenticated(true);
              }

            } catch (e) {
                setIsUserAuthenticated(false);
            }
          };
        
          getUserToken();

          setLoading(false);

    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default ContentWrapper