interface AppSettings {
	cpsConfiguratorApi: {
		endpoint: string;
		hashkey: string;
	},
	authProvider: {
		domain: string;
		clientId: string;
	},
	dipProfile: {
		endpoint: string;
	},
	pricingData: {
		customerno: string;
		salesorg: string;
	},
	ha_cm: {
		HA_CM_Hose_Image: string;
		HA_CM_Fitting_Image: string;
	},	
}
const settings: AppSettings = {
	cpsConfiguratorApi: JSON.parse(process.env.REACT_APP_CPSCONFIGURATOR_API || '{}'),
	authProvider: JSON.parse(process.env.REACT_APP_AUTH_PROVIDER || '{}'),
	dipProfile: JSON.parse(process.env.REACT_APP_DIP_PROFILE_EDIT_URL || '{}'),
	pricingData: JSON.parse(process.env.REACT_APP_PRICING || '{}'),
	ha_cm: JSON.parse(process.env.REACT_APP_HA_CM || '{}')
};

export default settings;
