import { CPSConfigEventCommands } from '@danfoss/cpsconfigurator';
import React from 'react';
import { ConfigResultProps } from './types';

const ConfigResult: React.FC<ConfigResultProps> = ({ showConfigEvent, onDisplay, onReconfigure }) => {

    if (showConfigEvent && showConfigEvent.command === CPSConfigEventCommands.complete && showConfigEvent.result) {
        return (
            <div className="df-container mb-5" >
                <div className="df-col-6">
                    <h4>Result from configurator</h4>
                    <div id="alert" className='df-alert alert-success' role="alert">
                        <div className="df-alert-message">
                            <p className="alert-heading">Configuration complete</p>
                        </div>
                    </div>
                </div>
                <button className='ml-2 mt-4 df-btn-primary auto-width' onClick={() => onDisplay()}>Display result</button>
            </div>
        );
    }

    if (showConfigEvent && CPSConfigEventCommands.show && showConfigEvent.result) {
        return (
            <div className="df-container mb-5" >
                <h4>Result from configurator</h4>
                <div className={`df-col-xs-4 df-col-sm-1 df-col-md-1 df-col-lg-8 df-col-8`}>
                    <table className="df-table" aria-label="result">
                        <thead>
                            <tr>
                                <th>Characteristic</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showConfigEvent.result.modelCode &&
                                <tr><td><strong>Model code</strong></td><td>{showConfigEvent.result.modelCode}</td></tr>
                            }
                            {showConfigEvent.result.configId &&
                                <tr><td><strong>Configuration id</strong></td><td>{showConfigEvent.result.configId}</td></tr>
                            }
                            {showConfigEvent.result.characteristics?.map((cstic, key) => {
                                return (<tr key={key}><td><strong>{cstic.name || cstic.id}</strong></td><td>{cstic.value}</td></tr>)
                            })}
                             {showConfigEvent.result.xml && 
                                <tr><td>XML: </td><td>{(showConfigEvent.result.xml as string).substring(0, 50)}</td></tr>
                             }
                        </tbody>
                    </table>
                </div>
                <button className='ml-2 mt-4 df-btn-primary auto-width' onClick={() => onReconfigure()}>Reconfigure</button>
            </div>
        );
    }

    return null;
}

export default ConfigResult;