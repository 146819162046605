import '@danfoss/mosaic/css/mosaic.css';
import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import UserMenuSelector from '../UserMenuSelector'

const Header: React.FC = () => {

    return (
        <BrowserRouter>
            <header className="df-header">
                <div className="df-container df-header-container">
                    <div className="df-logo-container">
                        <Link to="/" rel="noopener noreferrer" className="df-logo" aria-label="Home page">
                            <picture>
                                <img src="https://cdn.mosaic.danfoss.com/npm/mosaic-assets@latest/dist/logos/danfoss.svg" alt="Danfoss logo"/>
                            </picture>
                        </Link>
                    </div>
                    <nav className="header-nav-container" aria-label="header links">
                        <ul>
                            <UserMenuSelector />
                        </ul>
                    </nav>
                </div>
            </header>
        </BrowserRouter>
    )
}

export default Header